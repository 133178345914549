<template>
  <div class="order-status-indicator">
    <div class="order-status-indicator-indicator row">
      <!-- Status Column -->
      <div
        v-for="(status, index) in statusDates"
        :key="`order-status-${index}`"
        class="col d-flex align-items-center flex-column"
      >
        <!-- Status Icon and Line -->
        <div class="d-flex justify-content-end">
          <div
            v-if="index > 0"
            class="order-status-indicator-indicator-line"
            :class="status.value.length > 0 ? 'bg-success' : 'bg-gray'"
          ></div>
          <div
            v-if="status.value.length > 0"
            class="
              order-status-indicator-indicator-icon
              d-flex
              align-items-center
              justify-content-center
              p-0
              bg-white
            "
          >
            <AppIcon
              name="checkmark_filled"
              class="text-success rounded-circle"
            ></AppIcon>
          </div>
          <div
            v-else
            class="
              order-status-indicator-indicator-icon
              border border-2 border-gray
              rounded-circle
              bg-gray-light-3
            "
          ></div>
        </div>
        <span class="text-center">{{ status.text }}</span>
        <span
          v-if="status.value.length > 0"
          class="d-none d-md-block text-small"
        >
          {{ status.value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import { STATUS } from '@/shared/constants/OrderConstants';

export default {
  name: 'OrderStatusIndicator',

  components: { AppIcon },

  props: {
    pending: { type: String, default: '' },
    approved: { type: String, default: '' },
    quoteSent: { type: String, default: '' },
    quoteApproved: { type: String, default: '' },
    completed: { type: String, default: '' },
  },

  data() {
    return {
      STATUS,
      statusList: {
        [STATUS.REQUEST_PENDING.value]: 'Buyer RFQ Sent',
        [STATUS.REQUEST_APPROVED.value]: 'Request for RFQ',
        [STATUS.QUOTE_SENT.value]: 'RFQ Pending',
        [STATUS.QUOTE_APPROVED.value]: 'RFQ Received',
        [STATUS.COMPLETED.value]: 'RFQ Approved',
      },
    };
  },

  computed: {
    statusDates() {
      return [
        {
          text: 'Buyer RFQ Sent',
          value: this.pending,
        },
        {
          text: 'Request for RFQ',
          value: this.approved,
        },
        {
          text: 'RFQ Sent',
          value: this.quoteSent,
        },
        {
          text: 'RFQ Approved',
          value: this.quoteApproved,
        },
        {
          text: 'Completed',
          value: this.completed,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.order-status-indicator {
  &-indicator {
    &-icon {
      position: relative;
      z-index: 10;
      height: 20px;
      width: 20px;
    }
    &-line {
      margin-right: 0.6rem;
      margin-top: 0.6rem;
      height: 3px;
      width: 100%;
      position: absolute;
    }
  }
}
</style>
