<template>
  <div class="row">
    <!-- Order Summary -->
    <div class="col-12 col-xl-8">
      <AppCard class="p-3 h-100">
        <div class="row">
          <AppTextLabeled label="RFQ NO." class="col-6 col-md-3">
            <p class="font-weight-5 text-truncate w-100">
              #{{ order.id | rfqFormat }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="PO NO." class="col-6 col-md-3">
            <p class="font-weight-5 text-truncate w-100">
              #{{ order.id | rfqFormat }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="Project Name" class="col-12 col-md-3">
            <p class="font-weight-5 text-truncate">
              {{ order.project_name }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="Status" class="col-12 col-md-3">
            <!-- for future implementation when quote has been approved -->
            <span
              v-if="
                quote &&
                (quote.status === STATUS.QUOTE_APPROVED.quote_value ||
                  quote.status === STATUS.COMPLETED.quote_value)
              "
              class="
                p-1
                text-small
                bg-success-dark-2
                text-white
                rounded
                d-inline-flex
              "
            >
              <AppIcon name="checkmark"></AppIcon>
              {{
                quote.status === STATUS.COMPLETED.quote_value
                  ? STATUS.COMPLETED.vendor_text
                  : STATUS.QUOTE_APPROVED.vendor_text
              }}
            </span>
            <span
              v-else
              class="p-1 font-weight-5 text-small bg-special-light-4"
            >
              {{
                quote && quote.status === STATUS.QUOTE_SENT.quote_value
                  ? STATUS.QUOTE_SENT.vendor_text
                  : STATUS.REQUEST_APPROVED.vendor_text
              }}
            </span>
          </AppTextLabeled>

          <!-- Bidding Time for mobile view -->
          <AppTextLabeled
            v-if="!quote || quote.status === STATUS.QUOTE_SENT.quote_value"
            label="Bidding Time"
            class="mt-2 col-12 d-md-none"
          >
            <p class="m-0 font-weight-5 text-primary">
              {{ biddingTime }}
            </p>
          </AppTextLabeled>
        </div>

        <AppDivider class="mt-2 d-md-none"></AppDivider>

        <div class="mt-2 row">
          <AppTextLabeled label="Order Date" class="col-12 col-md-3">
            <p class="m-0 mr-1 font-weight-5 font-weight-5y d-inline-block">
              {{ order.created_at | readableDate(false) }}
            </p>
            <p class="m-0 font-weight-5 text-gray d-inline-block">
              {{ order.created_at | readableTime }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled
            label="Bidding End Date"
            class="mt-3 mt-lg-0 col-12 col-md-3"
          >
            <p class="font-weight-5 text-truncate">
              {{ order.bidding_end_date | readableDate(false, true) }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled
            label="Expected Delivery Date "
            class="col-12 col-md-3"
          >
            <p class="font-weight-5 text-truncate">
              {{ order.delivery_date | readableDate(false, true) }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled
            label="Bidding Time"
            class="col-12 col-md-3 d-none d-md-block"
          >
            <p class="font-weight-5 text-primary">
              {{ biddingTime }}
            </p>
          </AppTextLabeled>
        </div>

        <AppDivider class="mt-3 mb-3"></AppDivider>

        <div class="row">
          <AppTextLabeled label="CUSTOMER" class="col-12 col-md-3">
            <p class="font-weight-5 text-truncate">
              <img
                src="@/assets/pocketyard_logo_black.svg"
                width="20"
                class="d-inline mr-2 rounded-circle"
              />
              <span>
                {{ order.full_name }}
              </span>
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="COMPANY" class="col-12 col-md-3">
            <p class="font-weight-5 text-truncate">
              {{ order.user | userCompany }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="EMAIL" class="col-12 col-md-3">
            <p class="font-weight-5 text-truncate">
              {{ order.user.email }}
            </p>
          </AppTextLabeled>

          <AppTextLabeled label="PHONE" class="col-12 col-md-3">
            <p class="font-weight-5 text-truncate">
              {{ order.user.mobile_number || 'N/A' }}
            </p>
          </AppTextLabeled>
        </div>
      </AppCard>
    </div>
    <!-- Delivery Details -->
    <div class="col-12 col-xl-4 mt-2 mt-xl-0 d-none d-md-block">
      <AppCard class="p-3 h-100">
        <AppTextLabeled
          label="DELIVERY OPTIONS"
          text="Ship to address"
        ></AppTextLabeled>

        <AppTextLabeled label="SPECIFIC TRUCK" text="Piggy Back Forklift">
          <!-- Delivery Type -->
          <span>
            {{
              order.delivery_type === 'pick up' ? 'Pick up' : 'Ship to address'
            }}
          </span>
          <!-- Forklift -->
          <ul v-if="order.has_forklift" class="pl-4 d-inline-block">
            <li>Piggy Back Forklift</li>
          </ul>
          <!-- Union Drivers -->
          <ul v-if="order.is_union_delivery" class="pl-4 d-inline-block">
            <li>Union Delivery</li>
          </ul>
        </AppTextLabeled>

        <AppTextLabeled label="DELIVERY ADDRESS">
          <template v-slot:text>
            <p class="font-weight-5 m-0">
              {{
                `${order.address}, ${order.city}, ${order.state} ${order.zip_code}`
              }}
            </p>
          </template>
        </AppTextLabeled>

        <AppTextLabeled
          label="DELIVERY NOTE"
          :text="order.delivery_note | truncateText(50)"
          class="hoverable"
          @click="deliveryNotefull = true"
        >
        </AppTextLabeled>

        <AppModal v-model="deliveryNotefull" width="600px">
          <div class="p-2">
            <div class="d-flex align-items-center justify-content-between">
              <h4>DELIVERY NOTE</h4>
              <AppIcon
                hoverable
                name="close"
                @click="deliveryNotefull = false"
              ></AppIcon>
            </div>

            <p class="mt-3">
              {{ order.delivery_note }}
            </p>

            <AppBtn
              class="d-block mt-2 ml-auto mr-auto"
              @click="deliveryNotefull = false"
            >
              Close
            </AppBtn>
          </div>
        </AppModal>
      </AppCard>
    </div>
  </div>
</template>

<script>
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _countdownConfigs from '@/shared/mixins/_countdownConfigs';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import AppCard from '../../../shared/elements/AppCard.vue';
import AppTextLabeled from '../../../shared/elements/AppTextLabeled.vue';
import AppDivider from '../../../shared/elements/AppDivider.vue';
import { DateTime } from 'luxon';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import AppModal from '../../../shared/elements/AppModal.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';

export default {
  components: {
    AppCard,
    AppTextLabeled,
    AppDivider,
    AppIcon,
    AppModal,
    AppBtn,
  },
  name: 'VendorOrderDetails',

  mixins: [_readableDateTime, _countdownConfigs, _rfqFormat],

  props: {
    order: { type: Object, default: null },
    quote: { type: Object, default: null },
  },

  data() {
    return {
      STATUS,
      deliveryNotefull: false,
    };
  },

  watch: {
    order: {
      handler(val) {
        if (val.bidding_end_date !== null) {
          const endDate = val.bidding_end_date;
          this.startBiddingTime(endDate);
        }
      },
      deep: true,
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },
    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
    truncateText(value, maxChars = 100) {
      if (!value) {
        return 'N/A';
      } else if (value.length <= maxChars || maxChars <= 0) {
        return value;
      } else {
        return `${value.slice(0, maxChars + 1)}...`;
      }
    },
  },

  computed: {
    biddingTime() {
      if (this.countdownDisplayRaw.valueOf() > 0) {
        return `${this.countdownDisplayRaw.toFormat('hhHH : mm')}mm`;
      } else {
        return 'END DATE REACHED';
      }
    },
  },

  methods: {
    random() {
      return Math.ceil(Math.random() * 100000);
    },
    startBiddingTime(endDate) {
      if (endDate !== null) {
        this.end = DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss');
        this.startTick();
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (Object.keys(this.order).length > 0) {
        this.startBiddingTime(this.order.bidding_end_date);
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
