import { DateTime, Duration } from 'luxon';
export default {
  data() {
    return {
      start: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: 10 }),
      tick: null,
    };
  },
  watch: {
    now() {
      if (this.countdownFinished) {
        clearInterval(this.tick);
      }
    },
  },

  computed: {
    countdownRemaining() {
      return this.end.diff(this.start).toObject();
    },
    countdownDisplayRaw() {
      return Duration.fromObject(this.countdownRemaining);
    },
    countdownDisplay() {
      return Duration.fromObject(this.countdownRemaining).toFormat('hh:mm:ss');
    },
    countdownFinished() {
      return this.now >= this.end;
    },
  },

  methods: {
    startTick() {
      this.tick = setInterval(() => {
        this.start = DateTime.local().set({ milliseconds: 0 });
      }, 1000);
    },
  },
};
